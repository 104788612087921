import { ICookieContext } from '@msdyn365-commerce/core';

export const GetCookie = (cookieContext: ICookieContext, name: string) => {
    const cookieValue = cookieContext.get<string>(name);
    return cookieValue.value || '';
};

export const SetCookie = (cookieContext: ICookieContext, value: string, name: string, maxAge: number) => {
    cookieContext.set(name, value, {
        maxAge: maxAge
    });
};
